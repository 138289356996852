import { render, staticRenderFns } from "./personalV2.vue?vue&type=template&id=d942d25e&scoped=true"
import script from "./personalV2.vue?vue&type=script&lang=js"
export * from "./personalV2.vue?vue&type=script&lang=js"
import style0 from "./personalV2.vue?vue&type=style&index=0&id=d942d25e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d942d25e",
  null
  
)

export default component.exports